body {
    font-family: 'Proxima Nova' sans-serif;
}

.payouts-page {
    padding: 20px;
    margin-top: 92px;
    width: calc(100% - 295px);
    margin-left: 277px;
}

.payouts-header {
    background-color: #FFF4E7;
    border-radius: 16px;
    border: 1px solid #F1DBC0;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.payout-icon {
    width: 64px;
    height: 64px;
}

.heading-main {
    color: #202020;
    font-size: 25px;
    font-weight: 600;
}

.date-input-wrapper {
    display: flex;
    width: 180px;
    padding: 12px;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid #FFA626;
    background-color: #FFF;
}

.icon-with-text {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icon-with-text .icon {
    width: 20px;
    height: 20px;
}

.date-picker-input {
    border: none;
    outline: none;
    background: none;
    font-size: 1rem;
    color: #FFA626;
    width: 100%;
}

.custom-button {
    border-radius: 12px;
    background: linear-gradient(90deg, #FFA626 12.26%, #FF8E0E 89.58%);
    padding: 12px 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    cursor: pointer;
}

.search-filter-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.search-bars_pyout {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #FFA626;
    border-radius: 12px;
    padding: 12px;
    background-color: #FFF;
    position: relative;
    margin-left: 0px;
}

.search-inputs {
    width: 100%;
    border: none;
    outline: none;
    font-size: 1rem;
    color: #7D7D7D;
    background-color: transparent;
}

.search-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    color: #FFA626;
}

.payouts-table-wrapper {
    margin-top: 20px;
    overflow-x: auto;
    border-radius: 12px;
    background-color: #FFF;
    border: 1px solid #e0e0e0;
}

.payouts-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #FFF;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.payouts-table th,
.payouts-table td {
    padding: 16px 20px;
    text-align: left;
    font-size: 14px;
    background-color: #FFF;
    border-bottom: 1px solid #e0e0e0;
}

.payouts-table th:last-child,
.payouts-table td:last-child {
    border-right: 1px solid #e0e0e0;
}

.payouts-table thead th {
    background-color: #333;
    color: #FFF;
    font-weight: bold;
    padding: 23px 16px;
}

.payouts-table tbody tr {
    border-bottom: 1px solid #e0e0e0;
}

.payouts-table tbody tr:last-child td {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 0;
}

.payouts-table tbody tr:first-child td {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.payouts-table .status-success {
    color: green;
}

.payouts-table .status-hold {
    color: orange;
}

.payouts-table .status-failure {
    color: red;
}


.payouts-mobile-view {
    display: none;
}

.m-gigs-purchase {
    background-color: #333;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 15px;
    cursor: pointer;
}

.m-gigs-purchase .purchase-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.m-gigs-purchase .text h3 {
    margin: 0;
    font-size: 18px;
    color: white;
}

.m-gigs-purchase .arrow img {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
}

.m-gigs-purchase .arrow img.arrow_up {
    transform: rotate(180deg);
}

.m-purchase-body {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0 0 10px 10px;
    margin-bottom: 10px;
}

.m-purchase-text {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    color: black;
    border-bottom: 1px solid #ccc;
}

.m-purchase-text p {
    font-weight: bold;
}

.m-purchase-text h5 {
    margin: 0;
}

.status-success {
    color: green;
}

.status-hold {
    color: orange;
}

.status-failure {
    color: red;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-btn {
    background-color: white;
    border: 1px solid #ddd;
    color: orange;
    font-size: 16px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-btn.active {
    background-color: orange;
    color: white;
    border-color: orange;
}

.pagination-btn:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed;
}

.pagination-btn:hover:not(.active):not(:disabled) {
    background-color: #f2f2f2;
}


@media (max-width: 768px) {
    .payouts-page {
        width: 100%;
        margin-left: 0;
        padding: 20px 20px 100px 20px;
    }

    .payouts-header {
        flex-direction: column;
        text-align: center;
        padding: 10px;
    }

    .custom-button {
        margin-top: 10px;
    }

    .search-bars_pyout {
        width: 100%;
        margin-left: 0;
    }

    .payouts-table-wrapper {
        display: none;

    }

    .payouts-mobile-view {
        display: block;

    }
}