/* Base Styles */
body {
    font-family: 'Proxima Nova' sans-serif;
}

.transactions-page {
    padding: 20px;
    margin-top: 92px;
    width: calc(100% - 295px);
    margin-left: 277px;
    overflow-x: auto;
}

.transactions-header {
    background-color: #FFF4E7;
    border-radius: 16px;
    border: 1px solid #F1DBC0;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.transaction-icon {
    width: 64px;
    height: 64px;
}

.heading-main {
    color: #202020;
    font-size: 25px;
    font-weight: 600;
}

.date-input-wrapper {
    display: flex;
    width: 180px;
    padding: 12px;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid #FFA626;
    background-color: #FFF;
}

.icon-with-text {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icon-with-text .icon {
    width: 20px;
    height: 20px;
}

.date-picker-input {
    border: none;
    outline: none;
    background: none;
    font-size: 1rem;
    color: #FFA626;
    width: 100%;
}

.custom-button {
    border-radius: 12px;
    background: linear-gradient(90deg, #FFA626 12.26%, #FF8E0E 89.58%);
    padding: 12px 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    cursor: pointer;
}




/* Search Bar Responsiveness */
.search-filter-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.search-bars {
    display: flex;
    align-items: center;
    width: 96%;
    border: 1px solid #FFA626;
    border-radius: 12px;
    padding: 12px;
    background-color: #FFF;
    position: relative;
    margin-left: 0px;
}

.search-inputs {
    width: 100%;
    border: none;
    outline: none;
    font-size: 1rem;
    color: #7D7D7D;
    background-color: transparent;
}

.search-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    color: #FFA626;
}

/* Table Responsiveness */
.transactions-table-wrapper {
    margin-top: 20px;
    overflow-x: auto;
    border-radius: 12px;
    background-color: #FFF;
    border: 1px solid #e0e0e0;
}

.transactions-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #FFF;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.transactions-table th,
.transactions-table td {
    padding: 16px 20px;
    text-align: left;
    font-size: 14px;
    background-color: #FFF;
    border-bottom: 1px solid #e0e0e0;
}

.transactions-table th:last-child,
.transactions-table td:last-child {
    border-right: 1px solid #e0e0e0;
}

.transactions-table thead th {
    background-color: #333;
    color: #FFF;
    font-weight: bold;
    padding: 23px 16px;
}

.transactions-table tbody tr {
    border-bottom: 1px solid #e0e0e0;
}

.transactions-table tbody tr:last-child td {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 0;
}

.transactions-table tbody tr:first-child td {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.transactions-table .status-success {
    color: green;
}

.transactions-table .status-hold {
    color: orange;
}

.transactions-table .status-failure {
    color: red;
}


.m-gigs-purchase {
    background-color: #333;

    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 15px;

    cursor: pointer;
}

.m-gigs-purchase .purchase-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.m-gigs-purchase .text h3 {
    margin: 0;
    font-size: 18px;
    color: white;
}

.m-gigs-purchase .arrow img {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
}

.m-gigs-purchase .arrow img.arrow_up {
    transform: rotate(180deg);
}



.transactions-page {
    padding: 20px;
    margin-top: 92px;
    width: calc(100% - 295px);

    margin-left: 277px;

    overflow-x: auto;

}

.transactions-header {
    background-color: #FFF4E7;
    border-radius: 16px;
    border: 1px solid #F1DBC0;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.transaction-icon {
    width: 64px;
    height: 64px;
}

.heading-main {
    color: #202020;
    font-size: 25px;
    font-weight: 600;
}

.date-input-wrapper {
    display: flex;
    width: 180px;
    padding: 12px;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid #FFA626;
    background-color: #FFF;
}

.icon-with-text {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icon-with-text .icon {
    width: 20px;
    height: 20px;
}

.date-picker-input {
    border: none;
    outline: none;
    background: none;
    font-size: 1rem;
    color: #FFA626;
    width: 100%;
}

.custom-button {
    border-radius: 12px;
    background: linear-gradient(90deg, #FFA626 12.26%, #FF8E0E 89.58%);
    padding: 12px 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    cursor: pointer;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-btn {
    background-color: white;
    border: 1px solid #ddd;
    color: orange;
    font-size: 16px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-btn.active {
    background-color: orange;
    color: white;
    border-color: orange;
}

.pagination-btn:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed;
}

.pagination-btn:hover:not(.active):not(:disabled) {
    background-color: #f2f2f2;
}




@media (max-width: 1440px) {
    .transactions-page {
        width: calc(100% - 250px);

        margin-left: 250px;
    }

    .search-filter-container {
        width: 73% !important;
        margin-left: 305px !important;
    }

    .search-bars {
        width: 128% !important;
        margin-left: -303px !important;
    }
}

@media (max-width: 1280px) {
    .transactions-page {
        width: calc(100% - 200px);
        margin-left: 200px;
    }
}

@media (max-width: 1024px) {


    .transactions-page {
        width: 74%;
        margin-left: 255px;
    }

    .transaction-icon {
        width: 45px;
        height: 64px;
    }


    .transactions-header {
        background-color: #FFF4E7;
        border-radius: 16px;
        border: 1px solid #F1DBC0;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px !important;
    }

    .date-input-wrapper {
        width: 150px;
        margin-bottom: 10px;

    }


    .search-filter-container {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .search-bars {
        width: 100% !important;
        margin-left: 0 !important;
    }


    .transactions-table-wrapper {
        margin-top: 20px;
        overflow-x: auto;
        border-radius: 12px;
        background-color: #FFF;
        border: 1px solid #e0e0e0;
    }

    .transactions-table {
        width: 100%;
        font-size: 14px;
    }

    .custom-button {
        width: 31%;
        margin-top: -8px;

    }

    .heading-main {
        margin-right: 10px;
    }

    .date-input-wrapper {
        width: 171px !important;
    }


}


@media (min-width: 769px) {


    .transactions-mobile-view {
        display: none;
    }
}

@media (max-width: 768px) {


    .transactions-page {
        width: 100%;
        margin-left: 0;
        padding: 10px 27px 107px 26px;

    }


    .transaction-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
    }


    .transactions-header {
        background-color: #FFF4E7;
        border-radius: 16px;
        border: 1px solid #F1DBC0;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;

        align-items: center;
        padding: 10px;
        flex-direction: column;

        text-align: center;
    }

    .date-input-wrapper {
        width: 200px !important;
        margin-bottom: 10px;
        margin-top: 5px;
    }


    .search-bars {
        width: 100%;
        margin: 10px 0;
        margin-left: 0 !important;
    }


    .search-filter-container {
        width: 100%;
        justify-content: center;
    }


    .transactions-mobile-view {
        display: block;
        width: 100%;
    }


    .m-gigs-purchase .purchase-btn {
        background-color: #333;
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .m-gigs-purchase .text h3 {
        margin: 0;
        font-size: 16px;
    }

    .purchase-btn .arrow img {
        width: 20px;
        height: 20px;
    }


    .m-purchase-body {
        padding: 10px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 0 0 10px 10px;
        margin-bottom: 10px;
    }

    .m-purchase-text {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        color: black;
        border-bottom: 1px solid #ccc;

    }


    .m-purchase-body .m-purchase-text:last-child {
        border-bottom: none;

    }

    .m-purchase-text p {
        font-weight: bold;
    }

    .m-purchase-text h5 {
        margin: 0;
    }


    .status-success {
        color: green;
    }

    .status-hold {
        color: orange;
    }

    .status-failure {
        color: red;
    }


    .transactions-table-wrapper {
        display: none;
    }
}

@media (max-width: 425px) {
    .transactions-page {
        padding: 15px 15px 100px 15px;
        margin-left: 0;
        width: 100%;
    }

    .transaction-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
    }

    .transactions-header {
        padding: 10px;
        text-align: center;
        flex-direction: column;
        display: flex;
        align-items: center;
        gap: 10px;

    }


    .date-input-wrapper {
        width: 100% !important;
        margin-bottom: 10px;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        padding: 10px;
    }


    .search-bars,
    .search-filter-container {
        width: 100%;
    }


    .transactions-mobile-view {
        display: block;
        width: 100%;
    }


    .m-gigs-purchase .purchase-btn {
        background-color: #333;
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .m-gigs-purchase .text h3 {
        margin: 0;
        font-size: 16px;
    }

    .purchase-btn .arrow img {
        width: 20px;
        height: 20px;
    }


    .m-purchase-body {
        padding: 10px;
        background-color: #fff;
        border-top: 1px solid #ccc;
        border-radius: 0 0 10px 10px;
        margin-bottom: 10px;
    }

    .m-purchase-text {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid #ccc;
    }

    .m-purchase-text p {
        font-weight: bold;
    }

    .m-purchase-text h5 {
        margin: 0;
    }


    .status-success {
        color: green;
    }

    .status-hold {
        color: orange;
    }

    .status-failure {
        color: red;
    }

    .transactions-table-wrapper {
        display: none;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .transactions-page {
        padding: 15px 15px 100px 15px;
    }

    .transaction-icon {
        width: 45px;
        height: 45px;
    }

    .heading-main {
        font-size: 22px;
    }

    .date-input-wrapper {
        width: 220px;
    }

    .custom-button {
        padding: 10px 20px;
    }
}