.sidebar {
  width: 250px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -250px;
  z-index: 1000;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease;
  overflow-x: hidden;
  scrollbar-width: none;
}

.sidebar.open {
  left: 0;
 
  /* padding-bottom: 52px; */
}


/* Profile Menu */
.profile-menu {
  background-color: #fff;
  padding: 10px 10px 10px 22px;
  width: 100%;
  margin-top: 5px;
}

.profile-item {
  padding: 12px 0;
  color: #333;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.profile-item img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}


/* Footer Section */
.sidebar-footer {
  background: #F8F8F8;
}

.sidebar-footer a {
  text-decoration: none;
  color: inherit;
}


.sidebar-footer span {
  text-decoration: none;
  color: inherit;
}

.icon-expand {
  transition: transform 0.3s ease;
}

.icon-expand.rotate {
  transform: rotate(180deg);
}



/* Sidebar Logo */
.sidebar-logo {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #202020;
}

.menu-section {
  width: 100%;
}

.menu-heading {
  font-size: 14px;
  font-weight: 400;
  padding: 20px;
  color: #6d6d6d;
  text-transform: uppercase;
  margin: 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background: radial-gradient(57.26% 57.26% at 50.00% 50.00%, #F2F0F0 0%, rgba(226, 226, 226, 0.00) 100%);
}

.menu-section:first-child .menu-heading {
  border-top: none;
}

.menu-item {
  padding: 12px 1px 11px 17px;
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.menu-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* Profile Menu */
.profile-menu {
  background-color: #fff;
  padding: 10px 10px 10px 22px;
  width: 100%;
  margin-top: 5px;
}

.profile-item {
  padding: 12px 0;
  color: #333;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.profile-item img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

/* Footer Section */
.sidebar-footer {
  background: #F8F8F8;
}

.sidebar-footer a {
  text-decoration: none;
  color: inherit;
}

.sidebar-footer span {
  text-decoration: none;
  color: inherit;
}

.icon-expand {
  transition: transform 0.3s ease;
}

.icon-expand.rotate {
  transform: rotate(180deg);
}


/* Sidebar Logo */
.sidebar-logo {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #202020;
  padding: 15px;
}

.bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
}


.page-content {
  padding-bottom: 70px;
}

.bottom-navbar a {
  text-decoration: none;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-navbar img {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

.bottom-navbar span {
  font-size: 12px;
  text-align: center;
}


@media (max-width: 768px) {
  .bottom-navbar {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    z-index: 1000;
    justify-content: space-around;
  }

  .bottom-navbar a {
    text-decoration: none;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bottom-navbar img {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }

  .bottom-navbar span {
    font-size: 12px;
  }

  .profile-menu {
    display: none;
  }

  .sidebar.open {
    left: 0;
   
    padding-bottom: 52px;
  }
  
}



@media (min-width: 769px) {
  .profile-menu {
    display: block;
  }

  .sidebar {
    left: 0;
    transition: none;
  }

  .bottom-navbar {
    display: none;
  }

}
.custom-logout-modal .modal-body p{
  text-align:start;
  margin: 10px 10px 10px 10px;
}
.custom-logout-modal .modal-content {
  border-radius: 10px;
  border: none;
  overflow: hidden;
  padding: 0px 0px 24px 0px;

}

.custom-logout-modal .modal-header {
  border-bottom: none;
}

.custom-logout-modal .modal-footer {
  justify-content: center;
  border-top: none;
  box-shadow:none;
}

.custom-logout-modal .btn-outline-dark {
 
    box-shadow:none;
}

.custom-logout-modal .btn-warning {
  background-color: #FF9F1C;
  color: white;
  border-radius: 30px;
  padding: 10px 25px;
  margin-left: 15px;
}
