body {
  font-family: 'Proxima Nova' sans-serif;
}

.agency-details-container {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 16px;
  color: #333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 115px;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.agency-details-container::-webkit-scrollbar {
  display: none;
}


.agency-header {
  background-color: #333;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-button {
  border-radius: 50%;
  padding: 8px;
}

.agency-form {
  background-color: #f9f9f9;
  padding: 20px;
  color: #333;
  border-radius: 0 0 10px 10px;
}

.agency-form .form-control {
  background-color: #eee;
  border: none;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid #E8E8E8;
}

.agency-form .form-control:read-only {
  background-color: #f1f1f1;
}

.update-button {
  display: flex;
  width: 220px;
  height: 56px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: linear-gradient(90deg, #FFA626 12.26%, #FF8E0E 89.58%);
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.agency-form .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #E8E8E8;
}


@media (max-width: 767px) {
  .agency-details-container {
    width: 100%;
    margin-left: 0;
    padding: 0px 0px 81px 0px;

  }
}


@media (min-width: 768px) and (max-width: 1023px) {
  .agency-details-container {
    width: 85%;

  }


}


@media (min-width: 1024px) and (max-width: 1279px) {
  .agency-details-container {
    width: 69%;
    margin-left: 276px;
  }


}


@media (min-width: 1280px) and (max-width: 1439px) {
  .agency-details-container {
    width: 70%;
    margin-left: 100px;
  }


}


@media (min-width: 1440px) and (max-width: 1919px) {
  .agency-details-container {
    width: 65%;
    margin-left: 280px;
  }


}


@media (min-width: 1920px) {
  .agency-details-container {
    width: 100%;
    margin-left: 300px;
  }


}