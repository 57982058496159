body {
    font-family: 'Proxima Nova' sans-serif;
}

.creator-profile {
    background-color: #F8F8F8;
    min-height: 100vh;
    width: 95%;
    padding-bottom: 20px;
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #F8F8F8;
}


/* Creators Heder Start */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF4E7;
    border: 1px solid #F1DBC0;
    border-radius: 8px 8px 0 0;
    padding: 20px;
    margin-top: 109px;
    width: 82%;
    margin-left: 286px;

}

.back-buttons img {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;
}

.profile-name {
    font-family: "Proxima Nova", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #404040;
    text-align: start;
    flex-grow: 1;
    margin-left: 14px;
}


.creator-account-btn {
    background: linear-gradient(90deg, #FFA626 0%, #FF8E0E 100%);
    color: white;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    height: 48px;
    text-transform: uppercase;
}



/* Creators Heder End */

/* Tab Bat Start */
.tab-bar {
    display: flex;
    justify-content: flex-end;
    background-color: #FFF4E7;
    border-left: 1px solid #F1DBC0;
    border-bottom: 1px solid #F1DBC0;
    border-right: 1px solid #F1DBC0;
    width: 82%;
    margin-left: 286px;
    background: #fff;
    border-radius: 0px 0px 8px 8px;
}


.tab-item {
    flex-grow: 1;
    text-align: center;
    padding: 16px 0;
    cursor: pointer;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #6D6D6D;
    border-bottom: 3px solid transparent;
    transition: border-color 0.3s ease, color 0.3s ease;
    border-right: 1px solid #F1DBC0;
}

.tab-item.active {
    color: #FFA626;
}

.tab-content {
    color: #404040;
    text-align: center;
    margin-top: 27px;
}

.tab-item.active {
    color: #FFA626;
}

.tab-content {
    color: #404040;
    text-align: center;
    margin-top: 27px;
}

/* Tab Bat End */

/* Ongoing Tab Start */
.table {
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    width: 82%;
    margin-left: 286px;
    background: FFF;
    background-color: #fff;

}

.table td {
    padding: 16px;
    font-size: 16px;
    text-align: justify;
    vertical-align: middle;
}


.table tbody tr {
    border-bottom: 1px solid #E8E8E8;
}

.table th {
    text-align: left;
    vertical-align: middle;

}

.thead-dark th {
    background-color: #333;
    color: white;
    padding: 23px 16px;
    text-align: left;
    font-size: 16px;
    font-family: "Proxima Nova", sans-serif;
}

.table thead th:first-child {
    border-top-left-radius: 16px;

}

.table thead th:last-child {
    border-top-right-radius: 16px;
}

.table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 16px;
}

.table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 16px;
}

/* Ongoing Tab End */


/* Search Bar Start */
.search-filter-container_profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 82%;
    margin-left: 286px;
    margin-top: 38px;
}

.search-bar {
    position: relative;
    width: 95%;
    border: 1px solid #ffa626;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background: white;
    margin-bottom: 20px;
}

.search-input {
    border: none;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    outline: none;
    font-size: 16px;
    color: #333;
}

.search-icon {
    position: absolute;
    right: 12px;
    color: #ffa626;
    font-size: 18px;
}

.filter-btn {
    margin-top: -19px;
    background-color: white;
    cursor: pointer;
}

.filter-img-icon {
    width: 20px;
    height: 20px;
}

/* Search Bar End */

/* Filter sidebar start */
.filter-sidebar {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transition: right 0.3s ease-in-out;
    z-index: 1000;
}

.filter-sidebar.open {
    right: 0;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #ddd;
}

.filter-header h4 {
    margin: 0;
    font-size: 20px;
}

.filter-header .close-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.filter-content {
    padding: 16px;
}

/* Filter sidebar End */

/* Modal container Start */
.modal-container {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 300px;
    background-color: white;
    border-left: 1px solid #E8E8E8;
    transition: right 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}


.modal-container.show {
    right: 0;
}

.modal-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}


.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.modal-header h2 {
    margin: 0;
    font-size: 20px;
}

.close-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}



.modal-body h4 {
    color: #6D6D6D;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.28px;
    margin: 0;

    padding: 13px 20px;
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    background: radial-gradient(57.26% 57.26% at 50% 50%, #F2F0F0 0%, rgba(226, 226, 226, 0.00) 100%);
    text-align: justify;
    margin-left: -20px;
    width: 116%;
}

.checkbox-list {
    margin-top: 19px;
}

.checkbox-list div {
    margin-bottom: 15px;
    text-align: justify;

}


.modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid #e0e0e0;
    margin-left: -20px;
    width: 116%;
    box-shadow: -4px 0px 10px 0px rgba(0, 0, 0, 0.10);
    background: #FFF;
    margin-bottom: -20px;

}

.reset-btn,
.apply-btn {
    padding: 10px 20px;
    border-radius: 12px;
    font-size: 14px;
    cursor: pointer;
}

.reset-btn {
    background-color: white;
    border: 1px solid #FFA626;
    color: #FFA626;
    font-weight: 600;
}

.apply-btn {
    background: linear-gradient(90deg, #FFA626 0%, #FF8E0E 100%);
    border: none;
    color: white;
    font-weight: bold;
}


.modal-body input[type="checkbox"] {
    transform: scale(1.5);
    margin-right: 10px;
}

/* Custom Checkbox Styling Start */
.custom-checkbox input[type="checkbox"] {
    appearance: none;
    outline: none;
    width: 11px;
    height: 11px;
    border: 1px solid #6D6D6D;
    cursor: pointer;
    padding: 0;
    border-radius: 0;
}


.custom-checkbox input[type="checkbox"]:checked {


    border-color: #FFA626;
    background-color: #FFF;
    background-image: url('../../../public/icons/Checked.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


.custom-checkbox label {
    cursor: pointer;
    font-size: 16px;
    color: #333;
}


/* Custom Checkbox Styling End */

/* Modal container End */


/* Profile Start */
.profile-container {
    width: 82%;
    margin: 40px 60px 49px 286px;
    border-radius: 12px;
    background-color: white;
    overflow: hidden;
    margin-right: 56px;
    padding: 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}


.profile-header-container {
    background-color: #333;
    color: white;
    padding: 27px;
    text-align: left;
    border-radius: 10px 10px 0 0;
}

.profile-header-title {
    font-size: 18px;
    font-weight: bold;
}


.profile-header {
    position: relative;
    height: 280px;
}

.profile-background {
    width: 100%;
    height: 139%;
    object-fit: cover;
    padding: 31px;
    border-radius: 42px;
}

.profile-picture {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: 4px solid white;
    position: absolute;
    bottom: -144px;
    left: 71px;

}


.profile-info {
    padding: 157px 20px 20px 73px;
    text-align: start;
}

.profile-info h2 {
    font-size: 24px;
    margin: 0;
}

.profile-info p {
    color: #888;
    margin-top: 5px;
}

.profile-bio {
    color: #666;
    font-size: 15px;
    margin-top: 10px;

}



.profile-stats {
    display: flex;
    justify-content: space-between;
    background-color: #FFF4E7;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 0px 83px 21px 71px;

}

.stat {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 25%;
    padding: 20px 0;
    border-left: 1px solid #f0d9bb;
}


.stat:first-child {
    border-left: none;
}


.stat img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}


.stat p {
    margin: 0;
    font-size: 16px;
    color: #404040;
    text-align: left;
}

.stat strong {
    color: #404040;
    font-size: 18px;
}

/* Profile End */

/* Personal Details Start */


.personal-header-container {
    background-color: #333;
    color: white;
    padding: 27px;
    border-radius: 10px 10px 0 0;
    text-align: left;
}

.personal-header-title {
    font-size: 18px;
    font-weight: bold;
}

.personal-details-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px 26px;
}

.personal-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.personal-input-group label {
    font-size: 16px;
    margin-bottom: 10px;
    color: #666;
    text-align: start;
}

.personal-input-group input {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    background-color: #f7f7f7;
    font-size: 16px;
    color: #333;
    resize: none;

}

.personal-input-group textarea {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    background-color: #f7f7f7;
    font-size: 16px;
    color: #333;
    resize: none;
    width: 202%;
}

.personal-input-group input:focus,
.personal-input-group textarea:focus {
    outline: none;
    border-color: #e5e5e5;

}

/* Personal Details End */

/* Social Media Links Start */
.social-media-container {

    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.social-header-container {
    background-color: #333;
    color: white;
    padding: 27px;
    border-radius: 10px 10px 0 0;
    text-align: left;
}

.social-header-title {
    font-size: 18px;
    font-weight: bold;
}

.social-media-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.social-input-group {
    display: flex;
    flex-direction: column;
}

.social-input-group label {
    font-size: 16px;
    margin-bottom: 10px;
    color: #666;
    text-align: start;
}

.social-input-group input {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    background-color: #f7f7f7;
    font-size: 16px;
    color: #333;
}

.social-input-group input {
    outline: none;
    border-color: #e5e5e5;

}

/* Social Media Links End */

/* Posts and Gigs Start */
.toggle-header {
    display: flex;
    justify-content: space-between;
    width: 82%;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 282px;
}

.left-header {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.tabs {
    display: flex;
    background-color: #f7f7f7;
    border-radius: 25px;
    padding: 4px;
}

.tab {
    padding: 10px 30px;
    border: none;
    background-color: white;
    cursor: pointer;
    border-radius: 25px;
    font-weight: bold;
    font-size: 16px;
    color: #666;
    border-radius: 16px 0px 0px 16px;
}

.tab.active {
    background: linear-gradient(90deg, #FFA626 12.26%, #FF8E0E 89.58%);
    color: #fff;

}


/* Post Start */
.custom-card {
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    overflow: hidden;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 40px;
}



.custom-card-header {
    padding: 10px 13px 10px 16px;
    background-color: #fff;
    border-bottom: 1px solid #eee;

}

.custom-card-body {
    padding: 15px;
}

.custom-card-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
    text-align: left;
}

.custom-card-text {
    color: #666;
    font-size: 14px;
    text-align: left;
}

.custom-card-img-top {

    width: 106%;
    height: 460px;
    object-fit: cover;
    border-top-left-radius: 0;
    margin-left: -14px;
    border-top-right-radius: 0;
}

.custom-card-footer {
    padding: 6px 0px 15px 15px;
    background-color: #fff;
}

.custom-card-footer img {
    width: 20px;
    height: 20px;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify-content-between {
    justify-content: space-between;
}

.mr-2 {
    margin-right: 8px;
}

.mr-3 {
    margin-right: 16px;
}

.ml-3 {
    margin-left: 12px;
}

/* Post End */




/* Gigs Start */
.content-box {
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    overflow: hidden;
    transition: all 0.3s ease;
    margin-bottom: 30px;
}

.content-img-container {
    position: relative;
}

.content-img {
    width: 100%;
    height: auto;
    border-radius: 16px 16px 0 0;
}


.profile-section {
    padding: 0px 0px 0px 16px;
}



.profile-section img {
    border: 2px solid white;
}

.content-title {

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.28px;
    color: #404040;
    text-align: start;
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    padding: 10px;
    margin: 10px;
}

.content-card-footer {
    padding: 0px 0px 0px 0px;
    background: white;

    text-align: center;
}

.content-card-footer .icons {
    display: flex;
    justify-content: center;
    margin-bottom: 19px;
}

.content-card-footer .icons span {
    margin: 0 26px;
    font-size: 20px;
    color: #666;
}

.content-card-footer .buy-button {
    display: block;
    background: linear-gradient(90deg, #FFA626 0%, #FF8E0E 100%);
    border-radius: 0 0 12px 12px;
    font-size: 16px;
    padding: 12px 0;
    width: 100%;
    color: #fff;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    margin-top: 10px;
}

.content-card {
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    overflow: hidden;
    max-width: 400px;
    margin: 0 auto;
    transition: all 0.3s ease;
}

.table-responsive{
    overflow-x: visible !important;
}

.table-view {
    display: none;
}

.toggle-view {
    display: block;
    margin-bottom: 100px;
}

.m-job-item {
    background-color: #333;
    border-radius: 8px;
    margin: 29px;
    color: white;
    cursor: pointer;
}

.m-job-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.m-job-title {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.m-job-arrow img {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
}

.arrow-up {
    transform: rotate(180deg);
}

.m-job-details {
    background-color: white;
    color: black;
    margin-top: 10px;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    transition: max-height 0.3s ease;
    border-radius: 0px 0 8px 8px;
}

.m-job-detail-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.m-job-detail-row:last-child {
    border-bottom: none;
}

.m-detail-title {
    font-weight: bold;
    font-size: 14px;
    color: #666;
}

.m-detail-value {
    font-size: 14px;
    text-align: right;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-btn {
    background-color: white;
    border: 1px solid #ddd;
    color: orange;
    font-size: 16px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-btn.active {
    background-color: orange;
    color: white;
    border-color: orange;
}

.pagination-btn:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed;
}

.pagination-btn:hover:not(.active):not(:disabled) {
    background-color: #f2f2f2;
}

.contents{
    margin-left: 66px; 
}


@media (min-width: 1441px) and (max-width: 1791px) {
    .contents .col-md-6 {
        margin-left: 111px; /* Add margin only for larger screens */
        
    }
    .contents .col-md-4 {
        margin-left: 175px; /* Add margin only for larger screens */
        
    }
}


@media (min-width: 1025px) and (max-width: 1440px) {


    .header-container {
        width: 77%;
        margin: 105px 10px 10px 285px;
        padding: 20px;
    }

    .profile-name {
        font-size: 20px;
    }

    .creator-account-btn {
        font-size: 16px;
        padding: 12px 24px;
    }

    .tab-bar {
        width: 77%;
        margin: -10px 0px 0px 285px;

    }

    .tab-item {
        font-size: 16px;
        padding: 16px 0;
    }

    .profile-container {
        width: 77%;
        margin: 30px 0px 0px 286px;
    }

    .table {
        width: 78%;
        margin: 0 auto 13px 280px;
    }

    .custom-card,
    .content-box {
        max-width: 85%;
        margin: 0 auto 30px;
    }

    .search-filter-container_profile {
        width: 77%;
        margin: 29px 0px 0px 286px;

    }

    .table td,
    .table th {
        font-size: 16px;
    }

    .profile-info h2 {
        font-size: 24px;
    }

    .profile-bio {
        font-size: 16px;
    }

    .stat p {
        font-size: 16px;
    }

    .personal-details-form {
        grid-template-columns: repeat(2, 1fr);
    }

    .personal-input-group input,
    .personal-input-group textarea {
        font-size: 16px;
    }

    .custom-card-title {
        font-size: 18px;
    }

    .content-title {
        font-size: 16px;
    }

    .buy-button {
        font-size: 16px;
    }

    .toggle-header {
        width: 77%;

    }

    .contents {
        margin-left: 226px;
    }



}

@media (max-width: 1024px) {

    .header-container {
        width: 68%;
        margin: 94px 0px 0px 285px;
        padding: 15px;
    }

    .profile-name {
        font-size: 18px;
    }

    .creator-account-btn {
        font-size: 15px;
        padding: 10px 20px;
    }

    .tab-bar {
        width: 68%;
        margin: 0px 10px 10px 284px;

    }

    .tab-item {
        font-size: 15px;
        padding: 12px 0;
    }

    .profile-container {
        width: 68%;
        margin: 20px 0px 0px 281px;
    }

    .table {
        margin: 0px 0px 0px 284px;
        width: 69%;
    }

    .custom-card,
    .content-box {
        width: 90%;
        margin: 0 auto 30px;
    }

    .search-filter-container_profile {
        width: 68%;
        margin: 29px 0px 0px 286px;
    }

    .search-bar {
        width: 90%;
    }

    .table td,
    .table th {
        font-size: 14px;
    }

    .profile-info h2 {
        font-size: 22px;
    }

    .profile-bio {
        font-size: 14px;
    }

    .stat p {
        font-size: 15px;
    }

    .personal-details-form {
        grid-template-columns: repeat(2, 1fr);
    }

    .personal-input-group input,
    .personal-input-group textarea {
        font-size: 14px;
    }

    .custom-card-title {
        font-size: 16px;
    }

    .content-title {
        font-size: 14px;
    }

    .buy-button {
        font-size: 14px;
    }

    .toggle-header {
        width: 69%;
    }

    .contents {
        margin-left: 251px;
    }

    .content-card-footer .icons span {
        font-size: 12px;

        margin-left: 15px;

    }
}

@media (min-width: 769px) {
    .toggle-view {
        display: none;
    }

    .table-view {
        display: block;
    }
}

@media (max-width: 768px) {
    .header-container {
        flex-direction: row;
        align-items: center;
        width: 93%;
        margin: 92px 0px 0px 21px;

    }

    .tab-bar {
        flex-direction: column;
        align-items: center;
        width: 93%;
        margin: 0px 0px 0px 21px;


    }

    .creator-account-btn {
        width: 32%;
        text-align: center;
    }

    .profile-name {
        text-align: center;
        margin-left: -266px;
        margin-top: -1px;

    }

    .tab-item.active {
        color: #FFA626;
        border-right: none;
    }

    .tab-item {
        padding: 12px 0;
        border-right: none;
    }

    .profile-container {
        width: 95%;
        margin: 20px 0px 84px 20px;
    }

    .table {
        width: 95%;
        margin: 0 auto;
    }

    .custom-card,
    .content-box {
        max-width: 100%;
    }


    .toggle-header {
        margin-left: 123px;
        width: 80%;
    }

    .contents {
        margin-left: -9px;
        margin-bottom: 100px;
    }

    .content-card-footer .icons span {
        font-size: 12px;

        margin-left: 15px;

    }

    .search-filter-container_profile {
        width: 93%;
        margin: 29px 0px 0px 23px;
    }

    .col-md-6{
        width: 100% !important;
    }



}

@media (max-width: 425px) {


    .header-container {
        width: 90%;
        margin: 87px auto 20px;
        padding: 10px;
        flex-direction: row;
        text-align: center;
    }


    .profile-name {
        font-size: 16px;
        margin-top: 2px;
        margin-left: -76px;
    }

    .creator-account-btn {
        font-size: 12px;
        padding: 0px 16px;
        margin-top: 10px;
    }

    .tab-bar {
        flex-direction: column;
        width: 90%;
        margin: -21px auto;
    }

    .tab-item {
        padding: 10px 0;
        font-size: 14px;
    }

    .profile-container {
        width: 95%;
        margin: 60px auto;
    }

    .table {
        width: 95%;
        margin: 0 auto;
    }

    .custom-card,
    .content-box {
        width: 100%;
        margin: 0 auto 20px;
    }

    .custom-card-img-top,
    .content-img {
        width: 100%;
        height: auto;
    }

    .content-card-footer .icons span {
        font-size: 12px;
    }



    .personal-details-form {
        grid-template-columns: repeat(1, 1fr);
    }

    .personal-input-group textarea {
        width: 98%;
    }

    .custom-card-title,
    .content-title {
        font-size: 14px;
    }

    .buy-button {
        font-size: 12px;
        padding: 10px;
    }

    .search-bar {
        width: 85%;
        margin-left: -1px;
    }

    .left-header {
        font-size: 19px;
        margin-left: -50px;

    }

    .toggle-header {
        margin-left: 73px;
        width: 79%;
    }

    .contents {
        margin-left: 5px;
    }

    .profile-stats {
        margin: 0px 0px 0px 0px;

    }

    .stat {
        padding: 13px;
        flex-wrap: wrap;
        display: flex;

    }

    .stat p {

        text-align: center;
    }

}

@media (max-width: 375px) {

    .header-container {
        width: 92%;
        margin: 45px auto 20px;
        padding: 10px;
        flex-direction: column;
        text-align: center;
    }

    .profile-name {
        font-size: 15px;
    }

    .creator-account-btn {
        font-size: 11px;
        padding: 0px 14px;
    }

    .tab-bar {
        flex-direction: column;
        width: 92%;
        margin: 10px auto;
    }

    .tab-item {
        padding: 8px 0;
        font-size: 13px;
    }

    .profile-container {
        width: 92%;
        margin: 15px auto;
    }

    .custom-card,
    .content-box {
        width: 100%;
    }

    .custom-card-title,
    .content-title {
        font-size: 13px;
    }

    .buy-button {
        font-size: 11px;
    }
}

@media (max-width: 320px) {

    .header-container {
        width: 95%;
        margin: 40px auto 20px;
        padding: 8px;
        flex-direction: column;
        text-align: center;
    }

    .profile-name {
        font-size: 14px;
    }

    .creator-account-btn {
        font-size: 10px;
        padding: 6px 12px;
    }

    .tab-bar {
        flex-direction: column;
        width: 95%;
        margin: 10px auto;
    }

    .tab-item {
        padding: 6px 0;
        font-size: 12px;
    }

    .profile-container {
        width: 95%;
        margin: 10px auto;
    }

    .custom-card,
    .content-box {
        width: 100%;
    }

    .custom-card-title,
    .content-title {
        font-size: 12px;
    }

    .buy-button {
        font-size: 10px;
    }

    .search-bar {
        width: 100%;
    }

    .table td,
    .table th {
        font-size: 12px;
        padding: 8px;
    }

    .content-card-footer .icons span {
        font-size: 10px;
    }
}
