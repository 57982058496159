body {
  font-family: 'Proxima Nova' sans-serif;
}

.page-background {
  background-color: #F8F8F8;
  min-height: 100vh;
  padding-top: 20px;
  width: 80%;
  margin-left: 267px;
  max-width: 1600px;
}

.creators-container {
  padding: 18px 20px;
  border-radius: 16px;
  border: 1px solid #f1dbc0;
  background: #fff4e7;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 90px;
  width: 100%;
  height: auto;
}

.creators-heading {
  color: #202020;
  margin: 0;
  font-weight: bold;
}

.creators-button {
  background: linear-gradient(90deg, #FFA626 0%, #FF8E0E 100%);
  color: white;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 8px;
}

.search-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #FFA626;
  background: #FFF;
  padding: 0 10px;
  margin-bottom: 20px;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
  height: 100%;
  padding-left: 10px;
  font-size: 16px;
  color: #333;
}

.search-button {
  background: none;
  border: none;
  color: #FFA626;
  cursor: pointer;
  padding: 0 12px;
}

.sort-button {
  background: #fff;
  border: 1px solid #FFA626;
  border-radius: 8px;
  padding: 0 20px;
  height: 48px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  margin-left: 15px;
}

.dropdown-menu {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 200px;
  top: 60px;
  right: -17px;
}

.dropdown-item {
  padding: 10px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.sort-button .menu-icon {
  margin-right: 8px;
}


.custom-radio {
  position: relative;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.custom-radio input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.custom-radio input[type="radio"]:checked {
  border-color: #FFA626;
  background-color: #FFF;
  background-image: url('/public/icons/Radio.svg');
  background-position: center;
  background-size: 12px 12px;
  background-repeat: no-repeat;
}

.custom-radio label {
  cursor: pointer;
  font-size: 16px;
  color: #333;
}



/* Card */
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  padding: 20px;
}

.card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  padding: 20px;
  max-width: 300px;
  flex: 1 1 calc(25% - 40px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card-username_cretors {
  color: #888;
  font-size: 14px;
  margin-bottom: 15px;
  padding-top: 10px;
}

.card-img {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

.card-details {
  margin-bottom: 20px;
  margin-top: -17px;
}

.card-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.card-info .icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.view-button {
  background-color: transparent;
  color: #FFA726;
  border: 1px solid #FFA626;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.card h3 {
  font-size: 20px;
  color: #202020;
  margin: 15px 0;
  font-weight: bold;
  position: relative;
  text-align: start;
  padding-bottom: 20px;
}

.card h3:after {
  content: "";
  display: block;
  width: 96%;
  height: 1px;
  background-color: #E8E8E8;
  margin-top: 16px;
}


.custom-modal .modal-content {
  border-radius: 68px;
  padding: 0px !important;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.custom-modal .modal-header {
  border-bottom: none;
  background-color: #333;
  color: white;
  border-radius: 12px 12px 0 0;
}

.custom-modal .modal-title {
  font-size: 18px;
  font-weight: bold;
}

.custom-modal .modal-body {
  padding: 20px;
}

.custom-modal-footer {
  display: flex;
  justify-content: center;
  padding: 15px;
  border-top: none !important;
  margin-left: 0px !important;
  width: 100% !important;
  box-shadow: none !important;
  background: #FFF !important;
  margin-bottom: -20px !important;

}


.input-field {
  border-radius: 16px;
  padding: 15px 0px 15px 11px;
  border: 1px solid #E8E8E8;
  margin-bottom: 15px;
  font-size: 14px;
  background: #F8F8F8;
}

.input-field:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #E8E8E8;
  background: #F8F8F8;
}


.submit-button {
  background: linear-gradient(90deg, #FFA626 12.26%, #FF8E0E 89.58%);
  border: none;
  width: 100%;
  padding: 10px;
  font-weight: bold;
  border-radius: 16px;
  color: white;
  font-size: 16px;
  box-shadow: none;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #ff9100;
}

.creators-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.creators-heading {
  color: #202020;
  font-weight: bold;
  margin: 0;
}

.creators-button {
  background-color: #FFA726;
  color: white;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 8px;
}

.custom-close-btn img {
  width: 20px;
  height: 20px;
}

.otp-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-field {
  flex: 1;
  border-radius: 16px;
  padding: 15px;
  border: 1px solid #E8E8E8;
  margin-bottom: 15px;
  font-size: 14px;
  background: #F8F8F8;
}

.resend-button {
  background-color: transparent;
  border: none;
  color: #FFA726;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 10px;
  padding: 0;
}

.resend-button:hover {
  color: #FF8E0E;
}

.otp-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-field {
  flex: 1;
  border-radius: 16px;
  padding: 15px;
  border: 1px solid #E8E8E8;
  margin-bottom: 15px;
  font-size: 14px;
  background: #F8F8F8;
}

.resend-container {
  display: flex;
  justify-content: flex-end;
}

.resend-link {
  color: #FF8E0E;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
}




/* Model End */

.pagination_creators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-btn_creators {
  background-color: white;
  border: 1px solid #ddd;
  color: orange;
  font-size: 16px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-btn_creators.active {
  background-color: orange;
  color: white;
  border-color: orange;
}

.pagination-btn_creators:disabled {
  background-color: #f2f2f2;
  cursor: not-allowed;
  color: #ccc;
}

.pagination-btn_creators:hover:not(.active):not(:disabled) {
  background-color: #f2f2f2;
}

.pagination-btn_creators span {
  display: inline-block;
  line-height: 1;
}

.no-content-available{
  text-align: center;
}
.no-content-available img{
  width: 50%;
}


@media (max-width: 1440px) {
  .creators-container {
    width: 97%;
  }

  .creators-heading {
    font-size: 18px;
  }

  .creators-button {
    font-size: 14px;
  }


  .dropdown {
    width: 18%;
  }

  .card {
    flex: 1 1 calc(33.333% - 40px);
  }
  .no-content-available{
    text-align: center;
  }
  .no-content-available img{
    width: 50%;
  }
  .page-background {
    width: 80%; /* Adjust width for smaller screens */
    margin-left: auto; /* Center the container */
    margin-right: auto;
  }

}


@media (max-width: 1024px) {
  .creators-container {
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
    margin-top: 70px;
  }

  .creators-heading {
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 18px;

  }

  .creators-button {
    font-size: 14px;
    padding: 10px 15px;
    width: 24%;
    margin-top: 10px;
  }

  .search-container {
    width: 100%;
  }

  .card {
    flex: 1 1 calc(50% - 40px);
  }

  .dropdown {
    width: 26%;
  }
  .no-content-available{
    text-align: center;
  }
  .no-content-available img{
    width: 75%;
  }

  .page-background {
    width: 80%; /* For tablets and smaller screens */
    margin-left: auto;
    margin-right: auto;
  }
  

}


@media (max-width: 768px) {
  .creators-container {
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
    margin-top: 70px;
    height: auto;
    width: 100%;
  }

  .creators-heading {
    font-size: 16px;
  }

  .creators-button {
    font-size: 14px;
    padding: 10px;
    width: 28%;
  }

  .card {
    flex: 1 1 calc(50% - 40px);
  }

  .page-background {

    width: 93%;
    margin-left: 34px;
  }

  .card-container {
    padding: 20px 0px 0px 0px;
    margin: 30px;

  }
  .more{
    margin: 100px;
  }
  .no-content-available{
    text-align: center;
  }
  .no-content-available img{
    width: 50%;
  }
  
}


@media (max-width: 425px) {
  .creators-container {
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    margin-top: 74px;
  }

  .creators-heading {
    font-size: 16px;
  }

  .creators-button {
    font-size: 13px;
    padding: 8px 12px;
    width: 36%;
    margin-top: 1px;
  }

  .search-container {
    width: 100%;
  }

  .card {
    flex: 1 1 100%;
  }

  .dropdown {
    width: 46%;
  }
  .no-content-available{
    text-align: center;
  }
  .no-content-available img{
    width: 50%;
  }
  

}


@media (max-width: 375px) {
  .creators-container {
    padding: 18px 20px;
    border-radius: 16px;
    border: 1px solid #f1dbc0;
    background: #fff4e7;
    margin-bottom: 32px;
    margin-top: 76px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .creators-heading {
    font-size: 15px;
    margin-bottom: 15px;
    margin-top: -2px;
  }

  .creators-button {
    font-size: 12px;
    padding: 6px 10px;
    width: 100%;
  }

  .search-container {
    width: 100%;
  }

  .card {
    flex: 1 1 100%;
  }

  .page-background {
    width: 92%;
    margin-left: 17px;
  }
  .no-content-available{
    text-align: center;
  }
  .no-content-available img{
    width: 50%;
  }
  
}

