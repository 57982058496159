.notification-page {
    padding: 20px;
    max-width: 1600px;
    margin-top: 100px;
    margin-left: 268px;
}


.notification-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.notification-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    
}


.avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: cover;
   
}


.notification-details {
    flex: 1;
    margin-left: 15px;
    word-wrap: break-word;
}


.notification-details p {
    margin: 0;
    color: #555;
}


.notification-time {
    background: #f9e7d2;
    padding: 5px 10px;
    border-radius: 5px;
    color: #8d623e;
    font-size: 12px;
    flex-shrink: 0;
    margin-left: 15px;
    font-weight: 600;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-btn {
    background-color: white;
    border: 1px solid #ddd;
    color: orange;
    font-size: 16px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-btn.active {
    background-color: orange;
    color: white;
    border-color: orange;
}

.pagination-btn:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed;
}

.pagination-btn:hover:not(.active):not(:disabled) {
    background-color: #f2f2f2;
}




@media (max-width: 1024px) {
    .notification-card {
        padding: 10px;
        flex-direction: row;
    }

    .notification-time {
        font-size: 11px;
    }

    .avatar {
        width: 45px;
        height: 45px;
    }
}


@media (max-width: 768px) {
    .notification-page {
        margin-left: 0px !important;
    }

    .notification-card {
        flex-direction: column;
        align-items: flex-start;

    }

    .notification-details {
        margin-left: 0;
        margin-top: 10px;
    }

    .notification-time {
        align-self: flex-start;
        margin-top: 10px;
        margin-left: 0px;
    }

    .avatar {
        width: 40px;
        height: 40px;
    }

    .pagination {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 81px;
    }

    .pagination-btn {
        width: 35px;
        height: 35px;
        font-size: 14px;
        margin: 0 3px;
        line-height: 32px;

    }
}


@media (max-width: 480px) {
    .notification-page {
        padding: 10px;
    }

    .notification-card {
        padding: 10px;
        flex-direction: column;
    }

    .avatar {
        width: 35px;
        height: 35px;
    }

    .notification-time {
        font-size: 10px;
    }

    .pagination-btn {
        width: 30px;
        height: 30px;
        font-size: 12px;
        margin: 0 2px;
        line-height: 28px;
    }
}