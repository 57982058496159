body {
  font-family: 'Proxima Nova' sans-serif;
}

.settings-container {
  padding: 20px;
  margin-top: 92px;
  max-width: 1600px; 
  margin-left: 268px;
  background-color: #f9f9f9;
}

.setting-item {
  border: 1px solid #F1DBC0;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 0;
  cursor: pointer;
  background: #fff;
}

.setting-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.setting-header.active-header {
  background: linear-gradient(90deg, #333333 0%, #222222 100%);
  color: white;
  border-radius: 10px 10px 0 0;
}

.setting-header .icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.setting-header span {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
  color: orange;
}

.arrow-icon.rotate {
  transform: rotate(180deg);
}

.setting-content {
  padding: 15px;
  background-color: #fff;
  color: #666;
  font-size: 14px;
  border-top: 1px solid #F1DBC0;
  border-radius: 0 0 10px 10px;
}



/* Change Password Start */

.change-password-form {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
}

.password-input {
  width: 100%;
  padding: 12px 110px 12px 15px;
  border: 1px solid #ccc;
  border-radius: 16px;
  font-size: 14px;
  background: #E8E8E8;
  box-shadow: none;
  outline: none;
  transition: border 0.3s ease;
}

.password-input:focus {
  outline: none;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  width: 20px;
}

.forgot-password {
  font-size: 12px;
  color: #FF8E0E;
  text-decoration: none;
  position: absolute;
  right: 10px;
  top: 51px;
  font-size: 14px;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.update-password-btn {
  width: 100%;
  padding: 15px;
  background: linear-gradient(90deg, #FFA626 0%, #FF8E0E 100%);
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}


.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

/* Change Password End */



/* Payment section styles Start */
.payment-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 34px;
  background-color: white;
  border-radius: 8px;
  border-top: none;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 14px;
  color: #333;
  margin-bottom: 12px;
}

.form-input {
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 16px;
  background-color: #f5f5f5;
  font-size: 14px;
  width: 100%;
}

.icon {
  margin-right: 10px;
}

.arrow-icon {
  transition: transform 0.3s ease;
}

.arrow-icon.rotate {
  transform: rotate(180deg);
}

.form-input:focus {
  outline: none;
  border: 1px solid #ccc;
}

/* Payment section styles End */




/* Notifaction Start */
.toggle_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.left {
  flex: 1;
}

.left h3 {

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.32px;
  margin: 0;
}

.left h4 {
  color: #404040;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.28px;
  margin: 4px 0 0 0;
}


.right {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider.round {
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: orange;
}

input:checked+.slider:before {
  transform: translateX(20px);
}

.update_button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(90deg, #FFA626 0%, #FF8E0E 100%);
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}



.button.button2 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.setting-contents {
  padding: 34px;
  background-color: #fff;
  color: #666;
  font-size: 14px;
  border-top: 1px solid #F1DBC0;
  border-radius: 0 0 10px 10px;
}



@media (min-width: 1024px) and (max-width: 1440px) {
  .settings-container {
    width: 79%;
    margin: 73px 40px 10px 265px;
    padding: 20px;
  }
}


@media (max-width: 1024px) {
  .settings-container {
    width: 79%;
    margin: 73px 40px 10px 265px;
    padding: 20px;
   
  }

  .setting-header span {
    font-size: 14px;
  }

  .arrow-icon {
    width: 18px;
    height: 18px;
  }

  .payment-form {
    grid-template-columns: 1fr;

  }

  .form-input {
    font-size: 14px;
  }
}


@media (max-width: 768px) {
  .settings-container {
    width: 95%;
    margin: 80px auto;
    padding: 13px 0px 117px 0px;
  }

  .setting-header {
    padding: 10px;
    font-size: 14px;
  }

  .setting-content {
    font-size: 12px;
  }

  .update-password-btn,
  .update_button {
    padding: 12px;
    font-size: 14px;
  }

  .form-input {
    font-size: 12px;
    padding: 8px;
  }

  .payment-form {
    padding: 20px;
  }

  .eye-icon,
  .arrow-icon {
    width: 16px;
    height: 16px;
  }
}


.setting-item {
  border: 1px solid #F1DBC0;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 0;
  cursor: pointer;
  background: #fff;
}


.change-password-form {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
}

.password-input {
  width: 100%;
  padding: 12px 110px 12px 15px;
  border: 1px solid #ccc;
  border-radius: 16px;
  font-size: 14px;
  background: #E8E8E8;
  box-shadow: none;
  outline: none;
  transition: border 0.3s ease;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  width: 20px;
}


.payment-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 34px;
  background-color: white;
  border-radius: 8px;
}

.form-input {
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 16px;
  background-color: #f5f5f5;
  font-size: 14px;
  width: 100%;
}

.arrow-icon {
  transition: transform 0.3s ease;
}


.toggle_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.left h3 {
  font-size: 16px;
  font-weight: 600;
}

.left h4 {
  font-size: 14px;
  color: #404040;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

input:checked+.slider {
  background-color: orange;
}


.update_button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(90deg, #FFA626 0%, #FF8E0E 100%);
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}


@media (max-width: 480px) {
  .settings-container {
    width: 100%;
    padding: 10px 15px 100px 15px;
  }

  .setting-header {
    padding: 8px;
    font-size: 12px;
  }

  .setting-content {
    font-size: 11px;
  }

  .form-input {
    font-size: 12px;
  }
}