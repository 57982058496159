body {
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.28px;
    font-family: 'Proxima Nova' sans-serif;
}

.main-dahboard{
    max-width: 1600px;
    width: 95%;
    margin: 0 auto;
}

.dashboard-container {
    padding: 20px;
    border-radius: 12px;
    background: #FFF;
    width: 78%;
    margin-left: 286px;
    margin-top: 110px;
    border: 1px solid #E8E8E8;
    max-width: 1600px; 
}

.dashboard-container_followers {
    padding: 20px;
    border-radius: 12px;
    background: #FFF;
    width: 79%;
    margin-left: 286px;
    margin-top: 0px;
    border: 1px solid #E8E8E8;
    max-width: 1600px; 
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.stats-grid {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
}

.stat-card {
    background: rgba(255, 142, 14, 0.10);
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    width: 23%;
    border: 1px solid #F1DBC0;
    text-align: start;
}

.stat-card img {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
}

.stat-card p {
    font-size: 17px;
    color: #6D6D6D;
    margin: 0;
}

.stat-card h3 {
    font-size: 24px;
    margin: 0;
    color: #333;
}

.all-stats-heading {
    color: #404040;
    font-size: 20px;
}


/* Dropdwon menu start */
.custom-toggle-button {
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    color: #333;
    padding: 10px;
    min-width: 155px;
    text-align: left;
    background-color: #FFF;
    box-shadow: none;
}

.custom-toggle-button:hover,
.custom-toggle-button:focus,
.custom-toggle-button:active {
    background-color: #FFF !important;
    color: #333;
    border-color: #E8E8E8;
    box-shadow: none;
}

.custom-dropdown-menu {
    width: 150px !important;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    background-color: #fff;
}

.custom-menu-item {
    padding: 10px;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.custom-menu-item:focus,
.custom-menu-item:active,
.custom-menu-item:hover {
    background-color: #FFF !important;
    color: #333;
}

/* Dropdwon menu End */

/* Top Creators Start */
.creator-container {
    width: 81%;
    margin-left: 267px;
    margin-top: 30px;
}


.topcreator-card {
    border-radius: 16px;
    border: 1px solid #E8E8E8;
    background-color: #fff;
}

.topcreator-card-header {
    font-size: 18px;
    padding: 20px;
    font-weight: bold;
    border-radius: 16px 16px 0 0;
    background-color: #333;
    color: #fff;
}

.topcreator-card-body {
    padding: 20px 20px 0px 20px;
    background-color: #fff;
    border-radius: 16px;
}

.topcreator-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E8E8E8;
}

.topcreator-item:last-child {
    border-bottom: none;
}

.topcreator-item img.topcreator-img {
    width: 40px;
    height: 40px;
    border-radius: 15%;
    margin-right: 10px;
}

.topcreator-item p {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.topcreator-item span {
    font-size: 14px;
    color: #888;
}

.topcreator-item div {
    font-weight: bold;
    color: #333;
}

.ml-auto {
    margin-left: auto;
}

.ml-3 {
    margin-left: 1rem;
}

/* Top Creators End */


/* Chart Start */
.dashboardchart-container {
    padding: 20px;
    border-radius: 12px;
    background: #FFF;
    border: 1px solid #E8E8E8;
    width: 82%;
    margin-left: 286px;
    margin-top: 30px;
}

.dashboardchart-container h3 {
    color: #404040;
    font-size: 18px;
}

.dashboardchart-container p {
    color: #888;
    font-size: 14px;
    margin-bottom: 20px;
}

/* Chart End */

/*Dashboard Earnings Start */
.earnings-container {
    border: 1px solid #E8E8E8;
    border-radius: 12px;
    padding: 20px;
    background-color: #FFF;
    margin-bottom: 20px;
}

.earnings-container h3 {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E8E8E8;
    font-weight: bold;
}

.dashboard-earnings-container {
    width: 82%;
    margin-left: 286px;
    padding: 20px;
    max-width: 1600px; 
}


.earnings-list {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
}

.earnings-list li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
    padding-bottom: 10px;
}

.earnings-list .total {
    font-weight: bold;
}

.earnings-list .total-amount {
    color: #FF7A00;
    font-weight: bold;
    font-size: 20px;
}

.percentage-change {
    color: #00C853;
    font-weight: bold;
    font-size: 16px;
    margin-right: 10px;
}

.custom-toggle-button {
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    color: #333;
    padding: 10px;
    min-width: 120px;
    text-align: left;
    background-color: #FFF;
    box-shadow: none;
}

.custom-menu-item {
    padding: 10px;
    font-size: 14px;
}

.custom-menu-item:hover {
    background-color: #f8f8f8;
}


.earnings-list li:nth-child(5) {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.col-gap {
    margin-bottom: 20px;
    margin-right: 20px;
}

/*Dashboard Earnings End */

.custom-legend_dashboard {
    padding: 8px 16px;
    border-radius: 12px;
    background: #f8f8f8;
    border: 1px solid #e8e8e8;
    display: flex;
    gap: 20px;
    justify-content: center;
    width: fit-content;
}

.custom-legend_dashboard span {
    display: flex;
    align-items: center;
}

.custom-legend_dashboard i {
    display: inline-block;
    width: 20px;
    height: 3px;
    margin-right: 8px;
}

.blue-line_dashboard {
    background-color: #FF8E0E;
}

.orange-line_dashboard {
    background-color: #404040;
}


@media (max-width: 1024px) {
    .stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    
    .dashboard-earnings-container {
        width: 85%;
        margin-left: 286px;
        padding: 20px;
    }

    .dashboardchart-container {
        width: 78%;
        margin-left: 291px;
    }

    .custom-legend_dashboard {
        gap: 15px;
    }

    .custom-legend_dashboard i {
        width: 18px;
        height: 2px;
    }
    .dashboard-container_followers{
        width: 78%;
        margin-top: 0;
    }

    .creator-container{
        width: 80% !important;
        margin-left: 267px;
        margin-top: 30px;
       
    }

    .main-dahboard{
        max-width: 96%;
        width: 93%;
    }


}

@media (max-width: 768px) {
    .dashboard-container {
        width: 105%;
        margin-left: 62px;
        margin-right: 0;
        padding: 10px;
    }

    .dashboard-container_followers {
        width: 105%;
        margin-left: 62px;
        margin-right: 0;
        padding: 10px;
    }

    .stats-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    .dashboard-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .stat-card {
        width: 100%;
    }

    .dashboard-header {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 7px;
    }

    .all-stats-heading {
        text-align: left;
    }

    .custom-toggle-button {
        text-align: right;
    }

    .dashboard-earnings-container {
        padding: 10px;
    }

    .col-lg-4 {
        width: 190% !important;
        margin-left: -167px;
    }

    .col-lg-8 {
        width: 100%;
        margin-left: -167px;
    }

    .custom-toggle-button {
        font-size: 14px;
        width: 64%;
        text-align: center;
        margin-left: 44px;

    }

    .earnings-list li {
        font-size: 14px;
    }

    .earnings-list .total-amount {
        font-size: 18px;
    }

    .chart-container {
        height: 200px;
    }

    .dashboardchart-container {
        padding: 10px;
        width: 103%;
        margin-left: 75px;
    }

    .dashboardchart-container h3 {
        font-size: 16px;
    }

    .dashboardchart-container p {
        font-size: 12px;
    }

    .chart-container {
        height: 300px;
    }

    .creator-container {
        width: 108%;
        margin-left: 63px;
        margin-top: 30px;
        margin-bottom: 80px;
    }

    .mb-custom {
        margin-bottom: 10rem !important;
    }

    .custom-legend_dashboard {
        gap: 10px;
        padding: 8px;
        width: 31%;
        margin: 10px;
    }

    .custom-legend_dashboard i {
        width: 15px;
        height: 2px;
    }


}



@media (max-width: 480px) {
    .dashboard-container {
        width: 136%;
        padding: 10px;
        margin-left: 30px;

    }

    .dashboard-container_followers {
        width: 136%;
        padding: 10px;
        margin-left: 30px;
    }

    .stats-grid {
        grid-template-columns: 2fr;
        gap: 10px;
    }

    .stat-card {
        width: 100%;
    }

    .stat-card h3 {
        font-size: 20px;
    }

    .stat-card p {
        font-size: 15px;
    }

    .dashboard-header {
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
    }

    .all-stats-heading {
        font-size: 16px;
    }

    .custom-toggle-button {
        font-size: 14px;
        margin: 0px;
    }

    .dashboard-earnings-container {
        padding: 5px;
    }

    .custom-toggle-button {
        font-size: 12px;
    }

    .earnings-list li {
        font-size: 12px;
    }

    .earnings-list .total-amount {
        font-size: 16px;
    }

    .chart-container {
        height: 150px;
    }

    .dashboardchart-container {
        width: 129%;
        margin-left: 55px;
    }

    .dashboardchart-container h3 {
        font-size: 14px;
    }

    .dashboardchart-container p {
        font-size: 10px;
    }

    .chart-container {
        height: 250px;
    }

    .custom-legend_dashboard {
        padding: 6px 10px;
        gap: 8px;
        width: 100%;
    }

    .custom-legend_dashboard span {
        font-size: 12px;
    }

    .custom-legend_dashboard i {
        width: 12px;
        height: 2px;
    }


    .earnings_chart {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .percentage-change-container {
        margin-bottom: 10px;
        font-size: 12px;
        padding: 8px;
    }

    .creator-container {
        width: 141%;
        margin-left: 20px;

    }

}

@media (max-width: 360px) {
    .dashboard-container {
        padding: 5px;
        width: 155%;
    }

    .dashboard-container_followers {
        padding: 5px;
        width: 155%;
    }

    .stat-card h3 {
        font-size: 18px;
    }

    .stat-card p {
        font-size: 14px;
    }

    .custom-toggle-button {
        padding: 5px;
        min-width: 120px;
    }

    .custom-legend_dashboard {
        padding: 4px 8px;
        gap: 6px;
    }

    .custom-legend_dashboard span {
        font-size: 10px;
    }

    .custom-legend_dashboard i {
        width: 10px;
        height: 2px;
    }

}