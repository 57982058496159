body {
  font-family: 'Proxima Nova' sans-serif;
} 
.custom-header {
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 250px);
  margin-left: 250px;
  height: 72px;
  position: fixed;
  top: 0;
  z-index: 100;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 12px;
}

.back-button a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.back-icons {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.page-title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  flex-grow: 1;
  text-align: left;
}

.header-icons {
  display: flex;
  align-items: center;
  height: 72px;
  border-left: 1px solid #E8E8E8;
}

.icon-button {
  background: none;
  border: none;
  margin-left: 15px;
}

.header-icon {
  width: 27px;
  height: 27px;
}


.header-logo {
  display: none;
}

.notification-icon-container {
  position: relative;
  display: inline-block;
}

.notification-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: orange;
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: bold;
}



@media (max-width: 768px) {
  .custom-header {
    width: 100%;
    margin-left: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #202020;
  }

  .back-button {
    display: none;
  }

  .page-title {
    display: none;
  }

  .header-icons {
    margin-left: auto;
    border-left: none;
  }

  .icon-button {
    margin-left: 0;
  }

  .header-icon {
    width: 24px;
    height: 24px;
  }


  .header-logo {
    display: block;
    width: 120px;
  }

  .icon-button img {
    filter: brightness(0) invert(1);

  }
}


@media (max-width: 425px) {
  .header-logo {
    width: 100px;
  }

  .header-icon {
    width: 22px;
    height: 22px;
  }

  .header-icons {
    margin-top: 10px;
  }
}