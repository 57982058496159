body {
    font-family: 'Proxima Nova' sans-serif;
}

.members-page {
    padding: 20px;
    margin-top: 92px;
    width: calc(100% - 295px);
    margin-left: 277px;
}

.members-header {
    background-color: #FFF4E7;
    border-radius: 16px;
    border: 1px solid #F1DBC0;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.members-icon {
    cursor: pointer;
}

.heading-main {
    color: #202020;
    font-size: 25px;
    font-weight: 600;
}

.date-input-wrapper {
    display: flex;
    width: 180px;
    padding: 12px;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid #FFA626;
    background-color: #FFF;
}

.icon-with-text {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icon-with-text .icon {
    width: 20px;
    height: 20px;
}

.date-picker-input {
    border: none;
    outline: none;
    background: none;
    font-size: 1rem;
    color: #FFA626;
    width: 100%;
}

.custom-button {
    border-radius: 12px;
    background: linear-gradient(90deg, #FFA626 12.26%, #FF8E0E 89.58%);
    padding: 12px 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    cursor: pointer;
}

.search-filter-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.search-bars_pyout {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #FFA626;
    border-radius: 12px;
    padding: 12px;
    background-color: #FFF;
    position: relative;
    margin-left: 0px;
}

.search-inputs {
    width: 100%;
    border: none;
    outline: none;
    font-size: 1rem;
    color: #7D7D7D;
    background-color: transparent;
}

.search-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    color: #FFA626;
}

.members-table-wrapper {
    margin-top: 20px;
    overflow-x: auto;
    border-radius: 12px;
    background-color: #FFF;
    border: 1px solid #e0e0e0;
}

.members-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #FFF;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.members-table th,
.members-table td {
    padding: 16px 20px;
    text-align: left;
    font-size: 14px;
    background-color: #FFF;
    border-bottom: 1px solid #e0e0e0;
}

.members-table th:last-child,
.members-table td:last-child {
    border-right: 1px solid #e0e0e0;
}

.members-table thead th {
    background-color: #333;
    color: #FFF;
    font-weight: bold;
    padding: 23px 16px;
}

.members-table tbody tr {
    border-bottom: 1px solid #e0e0e0;
}

.members-table tbody tr:last-child td {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 0;
}

.members-table tbody tr:first-child td {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.members-table .status-success {
    color: green;
}

.members-table .status-hold {
    color: orange;
}

.members-table .status-failure {
    color: red;
}


.members-mobile-view {
    display: none;
}

.m-gigs-purchase {
    background-color: #333;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 15px;
    cursor: pointer;
}

.m-gigs-purchase .purchase-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.m-gigs-purchase .text h3 {
    margin: 0;
    font-size: 18px;
    color: white;
}

.m-gigs-purchase .arrow img {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
}

.m-gigs-purchase .arrow img.arrow_up {
    transform: rotate(180deg);
}

.m-purchase-body {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0 0 10px 10px;
    margin-bottom: 10px;
}

.m-purchase-text {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    color: black;
    border-bottom: 1px solid #ccc;
}

.m-purchase-text p {
    font-weight: bold;
}

.m-purchase-text h5 {
    margin: 0;
}

.status-success {
    color: green;
}

.status-hold {
    color: orange;
}

.status-failure {
    color: red;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-btn {
    background-color: white;
    border: 1px solid #ddd;
    color: orange;
    font-size: 16px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-btn.active {
    background-color: orange;
    color: white;
    border-color: orange;
}

.pagination-btn:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed;
}

.pagination-btn:hover:not(.active):not(:disabled) {
    background-color: #f2f2f2;
}


/* Modal Design */
.members_custom-modal .modal-content {
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    padding: 0;
}

.members_custom-modal .modal-header {
    background-color: #333;
    color: white;
    border-radius: 13px 13px 0 0;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.members_custom-modal .modal-header .close {
    color: white;
    font-size: 1.5rem;
}

.members_custom-modal .modal-title {
    font-size: 18px;
    font-weight: bold;
}

.members_custom-modal .modal-body {
    padding: 30px 20px;
}

.members_custom-modal-footer {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: white;
    border-top: none;
    width: 100%;
    margin: 0 auto;
    box-shadow: none;
    border-radius: 13px 13px 13px 13px;
}



.input-field {
    border-radius: 16px;
    padding: 15px;
    border: 1px solid #E8E8E8;
    background-color: #F8F8F8;
    font-size: 14px;
    margin-bottom: 15px;
    width: 100%;
}

.input-field:focus {
    outline: none;
    border-color: #FFA726;
}

.members_submit-button {
    background: linear-gradient(90deg, #FFA726 12.26%, #FF8E0E 89.58%);
    border: none;
    width: 100%;
    padding: 12px;
    font-weight: bold;
    border-radius: 16px;
    color: white;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.members_submit-button:hover {
    background-color: #FF8E0E;
}

.members_resend-link {
    color: #08A822;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    letter-spacing: 0.32px;
    cursor: pointer;
    text-decoration: none;
}

.members_resend-container {
    display: flex;
    justify-content: flex-end;
}

.delete-modal .modal-content {
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    padding: 0;
}

.delete-modal .modal-header {
    background-color: #333;
    color: white;
    border-radius: 13px 12px 0 0;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-modal .modal-title {
    font-size: 18px;
    font-weight: bold;
}

.delete-modal .modal-body {
    padding: 30px 20px;
}

.delete-modal-footer {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: white;
    border-top: none;
    width: 100%;
    margin: 0 auto;
    box-shadow: none;
    border-radius: 13px 12px 13px 13px;
}

.delete-modal-footer .delete-cancel-button {
    background: white;
    border: 1px solid #e0e0e0;
    color: #333;
    border-radius: 12px;
    padding: 10px 50px;
    font-weight: bold;
    cursor: pointer;
}

.delete-modal-footer .delete-cancel-button:hover {
    background: white;
    border: 1px solid #e0e0e0;
    color: #333;
}

.delete-confirm-button {
    background: linear-gradient(90deg, #ff8e0e, #ffa726);
    border: none;
    color: white;
    border-radius: 12px;
    padding: 10px 50px;
    font-weight: bold;
    cursor: pointer;
}

.delete-confirm-button:hover {
    background-color: #ff8e0e;
}
.invalid-feedback {
    
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: var(--bs-form-invalid-color);
}

@media (max-width:1024px) {
    .custom-button {
        width: 100%;
    }

    .search-bars_pyout {
        width: 136% !important;

        margin-left: 0px !important;
    }
}

@media (max-width:1440px) {
    .search-bars_pyout {

        width: 136% !important;

        margin-left: -301px;
    }
}




@media (max-width: 768px) {
    .members-page {
        width: 100%;
        margin-left: 0;
        padding: 20px 20px 100px 20px;
    }

    .members-header {

        text-align: center;
        padding: 10px;
    }

    .custom-button {
        margin-top: 10px;
        width: 100%;
    }

    .search-bars_pyout {
        width: 100%;
        margin-left: 0;
    }

    .members-table-wrapper {
        display: none;

    }

    .members-mobile-view {
        display: block;

    }
}

@media (max-width: 425px) {
    .heading-main {
        color: #202020;
        font-size: 19px;
        font-weight: 600;
    }

    .custom-button {
        margin-top: -3px;
        width: 100%;
        padding: 11px;
    }
}

@media (max-width: 375px) {
    .heading-main {
        color: #202020;
        font-size: 16px;
        font-weight: 600;
        text-align: justify;
    }

    .custom-button {
        margin-top: -3px;
        width: 100%;
        padding: 12px;
        font-size: 14px;
    }
}