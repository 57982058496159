body {
    font-family: 'Proxima Nova' sans-serif;
}

.revenuesplits-page {
    padding: 20px;
    margin-top: 92px;
    max-width: 1600px; 
    margin-left: 268px;
}

.revenuesplits-header {
    background-color: #FFF4E7;
    border-radius: 16px;
    border: 1px solid #F1DBC0;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.revenuesplits-main {
    color: #202020;
    font-size: 25px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.5px;
}

.revenuesplits-icon {
    width: 64px;
    height: 64px;
}

/* Date Input */
.date-input-wrapper {
    display: flex;
    width: 180px;
    padding: 12px;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid #FFA626;
    background-color: #FFF;
}

.icon-with-text {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icon-with-text .icon {
    width: 20px;
    height: 20px;
}

.date-text {
    font-size: 1rem;
    color: #FFA626;
}

.date-picker-input {
    border: none;
    outline: none;
    background: none;
    font-size: 1rem;
    color: #FFA626;
    width: 100%;
}

.custom-buttons {
    border-radius: 12px;
    background: linear-gradient(90deg, #FFA626 12.26%, #FF8E0E 89.58%);
    padding: 12px 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    color: white;
}

/* Search Filter */
.search-filter-containers {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.search-barss {
    display: flex;
    align-items: center;
    width: 96%;
    margin-left: -5px;
    border: 1px solid #FFA626;
    border-radius: 12px;
    padding: 12px;
    background-color: #FFF;
    position: relative;
}

.search-inputs {
    width: 100%;
    border: none;
    outline: none;
    font-size: 1rem;
    color: #7D7D7D;
    background-color: transparent;
}

.search-icons {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    color: #FFA626;
}

.filter-btns {
    margin-top: -3px;
    background-color: white;
    cursor: pointer;
}

.filter-img-icons {
    width: 20px;
    height: 20px;
}

/* Table */
.revenuesplits-table-wrapper {
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
    background-color: #FFF;
    border: 1px solid #e0e0e0;
}

.revenuesplits-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #FFF;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.revenuesplits-table th,
.revenuesplits-table td {
    padding: 16px 20px;
    text-align: left;
    font-size: 14px;
    background-color: #FFF;
    border-bottom: 1px solid #e0e0e0;
}

.revenuesplits-table thead th {
    background-color: #333;
    color: #FFF;
    font-weight: bold;
    padding: 23px 16px;
}

.revenuesplits-table tbody tr {
    border-bottom: 1px solid #e0e0e0;
}

.revenuesplits-table tbody tr:last-child td {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 0;
}

.revenuesplits-table tbody tr:first-child td {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}


.chart-container {
    border-radius: 20px;
    border: 1px solid #E8E8E8;
    background: #FFF;
    width: 100%;
    padding: 30px;
    margin-bottom: 20px;
    height: 555px;
}


.modal-container {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 300px;
    background-color: white;
    border-left: 1px solid #E8E8E8;
    transition: right 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-container.show {
    right: 0;
}

.modal-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.modal-header h2 {
    margin: 0;
    font-size: 20px;
}

.close-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.modal-body h4 {
    color: #6D6D6D;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.28px;
    margin: 0;
    padding: 13px 20px;
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
}

.checkbox-list {
    margin-top: 19px;
}

.checkbox-list div {
    margin-bottom: 15px;
}

.revenuesplits-mobile-view {
    display: none;
}


/* Chat Heading */
.custom-legend {
    padding: 8px 16px;
    border-radius: 12px;
    background: #f8f8f8;
    border: 1px solid #e8e8e8;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.custom-legend span {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.custom-legend i {
    display: inline-block;
    width: 18px;
    height: 2px;
    margin-right: 5px;
}

.blue-line {
    background-color: blue;
}

.orange-line {
    background-color: orange;
}

.green-line {
    background-color: green;
}

.black-line {
    background-color: black;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-btn {
    background-color: white;
    border: 1px solid #ddd;
    color: orange;
    font-size: 16px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-btn.active {
    background-color: orange;
    color: white;
    border-color: orange;
}

.pagination-btn:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed;
}

.pagination-btn:hover:not(.active):not(:disabled) {
    background-color: #f2f2f2;
}





@media (max-width: 1024px) {
    .revenuesplits-page {
        margin-left: 0; 
        margin-right: 0;
        width: 95%;
        padding: 10px;
    }

    .revenuesplits-header {
        flex-direction: column;
        text-align: center;
        width: 70%;
        margin-left: 272px;
    }

    .date-input-wrapper {
        width: 150px;
        margin-bottom: 10px;
    }

    .custom-buttons {
        margin-top: -10px;
    }

    .search-barss {
        margin-left: 268px;
        width: 91%;
    }

    .revenuesplits-table-wrapper {
        overflow-x: auto;
        width: 75%;
        margin-left: 258px;
    }

    .revenuesplits-table {
        min-width: 600px;

    }

    .chart-container {
        width: 71%;
        margin-left: 267px;
    }

    .custom-legend {
        padding: 8px 10px;
        gap: 5px;
        flex-wrap: wrap;
        justify-content: center;
        margin: 15px;
    }

    .custom-legend span {
        font-size: 12px;
    }

    .custom-legend i {
        width: 16px;
        height: 2px;
    }
}

@media (max-width: 768px) {
    .revenuesplits-header {
        flex-direction: column;
    }

    .chart-container {
        height: 615px;
        padding: 20px;
        width: 100%;
        margin-left: 5px;
    }

    .revenuesplits-table-wrapper {
        overflow-x: auto;
    }

    .revenuesplits-table {
        font-size: 14px;
        min-width: 600px;
    }

    .revenuesplits-page {
        padding: 20px 20px 100px 20px;
        width: 100%;
        margin-left: 0;
        margin-right: 0;

    }

    .revenuesplits-header {
        padding: 10px;
        text-align: center;
        flex-direction: column;
        width: 100%;
        margin-left: 5px;
    }

    .date-input-wrapper {
        width: 100%;
        margin-bottom: 10px;
    }

    .search-barss {
        margin-left: 1px;
        width: 93%;
    }

    .custom-buttons {
        margin-top: -6px;
    }

    .revenuesplits-table-wrapper {
        display: none;

    }

    .revenuesplits-mobile-view {
        display: block;

    }

    .m-gigs-purchase .purchase-btn {
        background-color: #333;
        color: #fff;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .m-gigs-purchase .text h3 {
        margin: 0;
        font-size: 16px;
    }

    .purchase-btn .arrow img {
        width: 20px;
        height: 20px;
        transition: transform 0.3s ease;
    }

    .purchase-btn .arrow_up {
        transform: rotate(180deg);
    }

    .m-purchase-body {
        padding: 10px;
        background-color: #fff;
        border-top: 1px solid #ccc;
        border-radius: 0 0 10px 10px;
        margin-bottom: 10px;
    }

    .m-purchase-text {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid #ccc;
    }

    .m-purchase-text p {
        font-weight: bold;
        margin: 0;
    }

    .m-purchase-text h5 {
        margin: 0;
        font-weight: normal;
    }

    .custom-legend {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}

@media (max-width: 425px) {
    .revenuesplits-header {
        padding: 10px;
        text-align: center;
        flex-direction: column;
    }

    .date-input-wrapper {
        width: 100%;
        margin-bottom: 10px;
    }

    .search-barss {
        width: 100%;
        margin-left: 0;
    }

    .revenuesplits-table-wrapper {
        overflow-x: auto;
    }

    .revenuesplits-table {
        font-size: 12px;
    }

    .chart-container {
        height: 685px;
    }

    .custom-buttons {
        width: 100%;
        text-align: center;
    }

    .revenuesplits-page {
        padding: 20px 10px 100px 10px;
       
        width: 100%;
        margin-left: 0;
        margin-right: 0;
       
    }

    .custom-legend {
        flex-direction: column;
        align-items: center;
        gap: 5px;
        margin-left: 25px;
    }

    .custom-legend span {
        font-size: 14px;
    }

    .custom-legend i {
        width: 15px;
        height: 2px;
    }
}

@media (max-width: 375) {
    .custom-legend {
      
        margin-left: 0px;
    }
    .custom-legend span {
        font-size: 12px;
    }
    .chart-container {
        
        width: 108% !important;
        
    }
    .revenuesplits-header {
        
        width: 105% !important;
       
    }
    
}